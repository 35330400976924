@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.btn-add {
  height: 24px;
  width: 24px;
  margin-top: -4px;

  i {
    &::before {
      font-size: 24px;
    }
  }
}

.cards-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.go-to-calendar {
  font-size: 14px;
  line-height: 16px;
}

.post-title {
  font-size: 10px;
  line-height: 12px;
}

.post-type {
  font-size: 10px;
  line-height: 1;
  margin-top: 5px;
  text-transform: uppercase;
}

.type-recommended {
  color: $brand-red;
}

.type-store {
  color: $brand-blue;
}

.img-none {
  width: 100%;
  background-color: #f1f1f1;
}

.scroll-area {
  width: 100%;
  overflow-x: hidden;
  padding: 0 0.8rem 2rem;
  user-select: none;
  max-height: 600px;
  overflow-y: auto;

  .swiper-wrapper {
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }

  .swiper-slide {
    width: 238px;
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }
  }
}
